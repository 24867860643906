import generic_device from '../images/sensor/generic-device.svg';

import alarm_aqi from '../images/sensor/alarm_aqi.svg';
import alarm_aqi_active from '../images/sensor/alarm_aqi_active.svg';
import alarm_battery from '../images/sensor/alarm_battery.svg';
import alarm_battery_active from '../images/sensor/alarm_battery_active.svg';
import alarm_bin_full from '../images/sensor/alarm_bin_full.svg';
import alarm_bin_full_active from '../images/sensor/alarm_bin_full_active.svg';
import alarm_bin_missing from '../images/sensor/alarm_bin_missing.svg';
import alarm_bin_missing_active from '../images/sensor/alarm_bin_missing_active.svg';
import alarm_ch2o from '../images/sensor/alarm_ch2o.svg';
import alarm_ch2o_active from '../images/sensor/alarm_ch2o_active.svg';
import alarm_cleaning_pad_missing from '../images/sensor/alarm_cleaning_pad_missing.svg';
import alarm_cleaning_pad_missing_active from '../images/sensor/alarm_cleaning_pad_missing_active.svg';
import alarm_co from '../images/sensor/alarm_co.svg';
import alarm_co_active from '../images/sensor/alarm_co_active.svg';
import alarm_co2 from '../images/sensor/alarm_co2.svg';
import alarm_co2_active from '../images/sensor/alarm_co2_active.svg';
import alarm_cold from '../images/sensor/alarm_cold.svg';
import alarm_cold_active from '../images/sensor/alarm_cold_active.svg';
import alarm_connectivity from '../images/sensor/alarm_connectivity.svg';
import alarm_connectivity_active from '../images/sensor/alarm_connectivity_active.svg';
import alarm_contact from '../images/sensor/alarm_contact.svg';
import alarm_contact_active from '../images/sensor/alarm_contact_active.svg';
import alarm_door_fault from '../images/sensor/alarm_door_fault.svg';
import alarm_door_fault_active from '../images/sensor/alarm_door_fault_active.svg';
import alarm_fire from '../images/sensor/alarm_fire.svg';
import alarm_fire_active from '../images/sensor/alarm_fire_active.svg';
import alarm_gas from '../images/sensor/alarm_gas.svg';
import alarm_gas_active from '../images/sensor/alarm_gas_active.svg';
import alarm_generic from '../images/sensor/alarm_generic.svg';
import alarm_generic_active from '../images/sensor/alarm_generic_active.svg';
import alarm_heat from '../images/sensor/alarm_heat.svg';
import alarm_heat_active from '../images/sensor/alarm_heat_active.svg';
import alarm_impact from '../images/sensor/alarm_impact.svg';
import alarm_impact_active from '../images/sensor/alarm_impact_active.svg';
import alarm_level from '../images/sensor/alarm_level.svg';
import alarm_level_active from '../images/sensor/alarm_level_active.svg';
import alarm_light from '../images/sensor/alarm_light.svg';
import alarm_light_active from '../images/sensor/alarm_light_active.svg';
import alarm_lost from '../images/sensor/alarm_lost.svg';
import alarm_lost_active from '../images/sensor/alarm_lost_active.svg';
import alarm_moisture from '../images/sensor/alarm_moisture.svg';
import alarm_moisture_active from '../images/sensor/alarm_moisture_active.svg';
import alarm_motion from '../images/sensor/alarm_motion.svg';
import alarm_motion_active from '../images/sensor/alarm_motion_active.svg';
import alarm_night from '../images/sensor/alarm_night.svg';
import alarm_night_active from '../images/sensor/alarm_night_active.svg';
import alarm_noise from '../images/sensor/alarm_noise.svg';
import alarm_noise_active from '../images/sensor/alarm_noise_active.svg';
import alarm_nox from '../images/sensor/alarm_nox.svg';
import alarm_nox_active from '../images/sensor/alarm_nox_active.svg';
import alarm_o3 from '../images/sensor/alarm_o3.svg';
import alarm_o3_active from '../images/sensor/alarm_o3_active.svg';
import alarm_occupancy from '../images/sensor/alarm_occupancy.svg';
import alarm_occupancy_active from '../images/sensor/alarm_occupancy_active.svg';
import alarm_pm1 from '../images/sensor/alarm_pm1.svg';
import alarm_pm1_active from '../images/sensor/alarm_pm1_active.svg';
import alarm_pm01 from '../images/sensor/alarm_pm01.svg';
import alarm_pm01_active from '../images/sensor/alarm_pm01_active.svg';
import alarm_pm10 from '../images/sensor/alarm_pm10.svg';
import alarm_pm10_active from '../images/sensor/alarm_pm10_active.svg';
import alarm_pm25 from '../images/sensor/alarm_pm25.svg';
import alarm_pm25_active from '../images/sensor/alarm_pm25_active.svg';
import alarm_power from '../images/sensor/alarm_power.svg';
import alarm_power_active from '../images/sensor/alarm_power_active.svg';
import alarm_presence from '../images/sensor/alarm_presence.svg';
import alarm_presence_active from '../images/sensor/alarm_presence_active.svg';
import alarm_problem from '../images/sensor/alarm_problem.svg';
// import alarm_problem_active from '../images/sensor/alarm_problem_active.svg'; // TODO Not available
import alarm_pump_device from '../images/sensor/alarm_pump_device.svg';
import alarm_pump_device_active from '../images/sensor/alarm_pump_device_active.svg';
import alarm_pump_supply from '../images/sensor/alarm_pump_supply.svg';
import alarm_pump_supply_active from '../images/sensor/alarm_pump_supply_active.svg';
import alarm_running from '../images/sensor/alarm_running.svg';
import alarm_running_active from '../images/sensor/alarm_running_active.svg';
import alarm_safety from '../images/sensor/alarm_safety.svg';
import alarm_safety_active from '../images/sensor/alarm_safety_active.svg';
import alarm_smoke from '../images/sensor/alarm_smoke.svg';
import alarm_smoke_active from '../images/sensor/alarm_smoke_active.svg';
import alarm_so2 from '../images/sensor/alarm_so2.svg';
import alarm_so2_active from '../images/sensor/alarm_so2_active.svg';
import alarm_stuck from '../images/sensor/alarm_stuck.svg';
import alarm_stuck_active from '../images/sensor/alarm_stuck_active.svg';
import alarm_tamper from '../images/sensor/alarm_tamper.svg';
import alarm_tamper_active from '../images/sensor/alarm_tamper_active.svg';
import alarm_tank_empty from '../images/sensor/alarm_tank_empty.svg';
import alarm_tank_empty_active from '../images/sensor/alarm_tank_empty_active.svg';
import alarm_tank_missing from '../images/sensor/alarm_tank_missing.svg';
import alarm_tank_missing_active from '../images/sensor/alarm_tank_missing_active.svg';
import alarm_tank_open from '../images/sensor/alarm_tank_open.svg';
import alarm_tank_open_active from '../images/sensor/alarm_tank_open_active.svg';
import alarm_tvoc from '../images/sensor/alarm_tvoc.svg';
import alarm_tvoc_active from '../images/sensor/alarm_tvoc_active.svg';
import alarm_vibration from '../images/sensor/alarm_vibration.svg';
import alarm_vibration_active from '../images/sensor/alarm_vibration_active.svg';
import alarm_water from '../images/sensor/alarm_water.svg';
import alarm_water_active from '../images/sensor/alarm_water_active.svg';
import battery_charging_state from '../images/sensor/battery_charging_state.svg';
import dishwasher_program from '../images/sensor/dishwasher_program.svg';
import docked from '../images/sensor/docked.svg';
import laundry_washer_program from '../images/sensor/laundry_washer_program.svg';
import level_aqi from '../images/sensor/level_aqi.svg';
import level_carbon_filter from '../images/sensor/level_carbon_filter.svg';
import level_ch2o from '../images/sensor/level_ch2o.svg';
import level_co from '../images/sensor/level_co.svg';
import level_co2 from '../images/sensor/level_co2.svg';
import level_hepa_filter from '../images/sensor/level_hepa_filter.svg';
import level_nox from '../images/sensor/level_nox.svg';
import level_o3 from '../images/sensor/level_o3.svg';
import level_pm1 from '../images/sensor/level_pm1.svg';
import level_pm01 from '../images/sensor/level_pm01.svg';
import level_pm10 from '../images/sensor/level_pm10.svg';
import level_pm25 from '../images/sensor/level_pm25.svg';
import level_radon from '../images/sensor/level_radon.svg';
import level_so2 from '../images/sensor/level_so2.svg';
import level_tvoc from '../images/sensor/level_tvoc.svg';
import measure from '../images/sensor/measure.svg';
import measure_aqi from '../images/sensor/measure_aqi.svg';
import measure_carbon_filter from '../images/sensor/measure_carbon_filter.svg';
import measure_ch2o from '../images/sensor/measure_ch2o.svg';
import measure_co from '../images/sensor/measure_co.svg';
import measure_co2 from '../images/sensor/measure_co2.svg';
import measure_content_volume from '../images/sensor/measure_content_volume.svg';
import measure_current from '../images/sensor/measure_current.svg';
import measure_data_rate from '../images/sensor/measure_data_rate.svg';
import measure_data_size from '../images/sensor/measure_data_size.svg';
import measure_distance from '../images/sensor/measure_distance.svg';
import measure_frequency from '../images/sensor/measure_frequency.svg';
import measure_gust_angle from '../images/sensor/measure_gust_angle.svg';
import measure_gust_strength from '../images/sensor/measure_gust_strength.svg';
import measure_hepa_filter from '../images/sensor/measure_hepa_filter.svg';
import measure_humidity from '../images/sensor/measure_humidity.svg';
import measure_luminance from '../images/sensor/measure_luminance.svg';
import measure_moisture from '../images/sensor/measure_moisture.svg';
import measure_monetary from '../images/sensor/measure_monetary.svg';
import measure_noise from '../images/sensor/measure_noise.svg';
import measure_nox from '../images/sensor/measure_nox.svg';
import measure_o3 from '../images/sensor/measure_o3.svg';
import measure_odor from '../images/sensor/measure_odor.svg';
import measure_ph from '../images/sensor/measure_ph.svg';
import measure_pm1 from '../images/sensor/measure_pm1.svg';
import measure_pm01 from '../images/sensor/measure_pm01.svg';
import measure_pm10 from '../images/sensor/measure_pm10.svg';
import measure_pm25 from '../images/sensor/measure_pm25.svg';
import measure_power from '../images/sensor/measure_power.svg';
import measure_pressure from '../images/sensor/measure_pressure.svg';
import measure_radon from '../images/sensor/measure_radon.svg';
import measure_rain from '../images/sensor/measure_rain.svg';
import measure_rain_intensity from '../images/sensor/measure_rain_intensity.svg';
import measure_rotation from '../images/sensor/measure_rotation.svg';
import measure_signal_strength from '../images/sensor/measure_signal_strength.svg';
import measure_so2 from '../images/sensor/measure_so2.svg';
import measure_speed from '../images/sensor/measure_speed.svg';
import measure_temperature from '../images/sensor/measure_temperature.svg';
import measure_tvoc from '../images/sensor/measure_tvoc.svg';
import measure_tvoc_index from '../images/sensor/measure_tvoc_index.svg';
import measure_ultraviolet from '../images/sensor/measure_ultraviolet.svg';
import measure_voltage from '../images/sensor/measure_voltage.svg';
import measure_water from '../images/sensor/measure_water.svg';
import measure_weight from '../images/sensor/measure_weight.svg';
import measure_wind_angle from '../images/sensor/measure_wind_angle.svg';
import measure_wind_strength from '../images/sensor/measure_wind_strength.svg';
import meter_gas from '../images/sensor/meter_gas.svg';
import meter_power from '../images/sensor/meter_power.svg';
import meter_rain from '../images/sensor/meter_rain.svg';
import meter_water from '../images/sensor/meter_water.svg';
import operational_state from '../images/sensor/operational_state.svg';

export function getSensorImages(capabilityId, capability) {
  const [actualId] = capabilityId.split('.');

  switch (actualId) {
    case 'alarm_generic':
      return {
        default: alarm_generic,
        active: alarm_generic_active,
      };
    case 'alarm_aqi':
      return {
        default: alarm_aqi,
        active: alarm_aqi_active,
      };
    case 'alarm_battery':
      return {
        default: alarm_battery,
        active: alarm_battery_active,
      };
    case 'alarm_bin_full':
      return {
        default: alarm_bin_full,
        active: alarm_bin_full_active,
      };
    case 'alarm_bin_missing':
      return {
        default: alarm_bin_missing,
        active: alarm_bin_missing_active,
      };
    case 'alarm_ch2o':
      return {
        default: alarm_ch2o,
        active: alarm_ch2o_active,
      };
    case 'alarm_cleaning_pad_missing':
      return {
        default: alarm_cleaning_pad_missing,
        active: alarm_cleaning_pad_missing_active,
      };
    case 'alarm_co':
      return {
        default: alarm_co,
        active: alarm_co_active,
      };
    case 'alarm_co2':
      return {
        default: alarm_co2,
        active: alarm_co2_active,
      };
    case 'alarm_cold':
      return {
        default: alarm_cold,
        active: alarm_cold_active,
      };
    case 'alarm_connectivity':
      return {
        default: alarm_connectivity,
        active: alarm_connectivity_active,
      };
    case 'alarm_contact':
      return {
        default: alarm_contact,
        active: alarm_contact_active,
      };
    case 'alarm_door_fault':
      return {
        default: alarm_door_fault,
        active: alarm_door_fault_active,
      };
    case 'alarm_fire':
      return {
        default: alarm_fire,
        active: alarm_fire_active,
      };
    case 'alarm_gas':
      return {
        default: alarm_gas,
        active: alarm_gas_active,
      };
    case 'alarm_heat':
      return {
        default: alarm_heat,
        active: alarm_heat_active,
      };
    case 'alarm_impact':
      return {
        default: alarm_impact,
        active: alarm_impact_active,
      };
    case 'alarm_level':
      return {
        default: alarm_level,
        active: alarm_level_active,
      };
    case 'alarm_light':
      return {
        default: alarm_light,
        active: alarm_light_active,
      };
    case 'alarm_lost':
      return {
        default: alarm_lost,
        active: alarm_lost_active,
      };
    case 'alarm_moisture':
      return {
        default: alarm_moisture,
        active: alarm_moisture_active,
      };
    case 'alarm_motion':
      return {
        default: alarm_motion,
        active: alarm_motion_active,
      };
    case 'alarm_night':
      return {
        default: alarm_night,
        active: alarm_night_active,
      };
    case 'alarm_noise':
      return {
        default: alarm_noise,
        active: alarm_noise_active,
      };
    case 'alarm_nox':
      return {
        default: alarm_nox,
        active: alarm_nox_active,
      };
    case 'alarm_o3':
      return {
        default: alarm_o3,
        active: alarm_o3_active,
      };
    case 'alarm_occupancy':
      return {
        default: alarm_occupancy,
        active: alarm_occupancy_active,
      };
    case 'alarm_pm1':
      return {
        default: alarm_pm1,
        active: alarm_pm1_active,
      };
    case 'alarm_pm01':
      return {
        default: alarm_pm01,
        active: alarm_pm01_active,
      };
    case 'alarm_pm10':
      return {
        default: alarm_pm10,
        active: alarm_pm10_active,
      };
    case 'alarm_pm25':
      return {
        default: alarm_pm25,
        active: alarm_pm25_active,
      };
    case 'alarm_power':
      return {
        default: alarm_power,
        active: alarm_power_active,
      };
    case 'alarm_presence':
      return {
        default: alarm_presence,
        active: alarm_presence_active,
      };
    case 'alarm_problem':
      return {
        default: alarm_problem,
        active: null,
      };
    case 'alarm_pump_device':
      return {
        default: alarm_pump_device,
        active: alarm_pump_device_active,
      };
    case 'alarm_pump_supply':
      return {
        default: alarm_pump_supply,
        active: alarm_pump_supply_active,
      };
    case 'alarm_running':
      return {
        default: alarm_running,
        active: alarm_running_active,
      };
    case 'alarm_safety':
      return {
        default: alarm_safety,
        active: alarm_safety_active,
      };
    case 'alarm_smoke':
      return {
        default: alarm_smoke,
        active: alarm_smoke_active,
      };
    case 'alarm_so2':
      return {
        default: alarm_so2,
        active: alarm_so2_active,
      };
    case 'alarm_stuck':
      return {
        default: alarm_stuck,
        active: alarm_stuck_active,
      };
    case 'alarm_tamper':
      return {
        default: alarm_tamper,
        active: alarm_tamper_active,
      };
    case 'alarm_tank_empty':
      return {
        default: alarm_tank_empty,
        active: alarm_tank_empty_active,
      };
    case 'alarm_tank_missing':
      return {
        default: alarm_tank_missing,
        active: alarm_tank_missing_active,
      };
    case 'alarm_tank_open':
      return {
        default: alarm_tank_open,
        active: alarm_tank_open_active,
      };
    case 'alarm_tvoc':
      return {
        default: alarm_tvoc,
        active: alarm_tvoc_active,
      };
    case 'alarm_vibration':
      return {
        default: alarm_vibration,
        active: alarm_vibration_active,
      };
    case 'alarm_water':
      return {
        default: alarm_water,
        active: alarm_water_active,
      };
    case 'battery_charging_state':
      return {
        default: battery_charging_state,
        active: null,
      };
    case 'dishwasher_program':
      return {
        default: dishwasher_program,
        active: null,
      };
    case 'docked':
      return {
        default: docked,
        active: null,
      };
    case 'laundry_washer_program':
      return {
        default: laundry_washer_program,
        active: null,
      };
    case 'level_aqi':
      return {
        default: level_aqi,
        active: null,
      };
    case 'level_carbon_filter':
      return {
        default: level_carbon_filter,
        active: null,
      };
    case 'level_ch2o':
      return {
        default: level_ch2o,
        active: null,
      };
    case 'level_co':
      return {
        default: level_co,
        active: null,
      };
    case 'level_co2':
      return {
        default: level_co2,
        active: null,
      };
    case 'level_hepa_filter':
      return {
        default: level_hepa_filter,
        active: null,
      };
    case 'level_nox':
      return {
        default: level_nox,
        active: null,
      };
    case 'level_o3':
      return {
        default: level_o3,
        active: null,
      };
    case 'level_pm1':
      return {
        default: level_pm1,
        active: null,
      };
    case 'level_pm01':
      return {
        default: level_pm01,
        active: null,
      };
    case 'level_pm10':
      return {
        default: level_pm10,
        active: null,
      };
    case 'level_pm25':
      return {
        default: level_pm25,
        active: null,
      };
    case 'level_radon':
      return {
        default: level_radon,
        active: null,
      };
    case 'level_so2':
      return {
        default: level_so2,
        active: null,
      };
    case 'level_tvoc':
      return {
        default: level_tvoc,
        active: null,
      };
    case 'measure':
      return {
        default: measure,
        active: null,
      };
    case 'measure_aqi':
      return {
        default: measure_aqi,
        active: null,
      };
    case 'measure_carbon_filter':
      return {
        default: measure_carbon_filter,
        active: null,
      };
    case 'measure_ch2o':
      return {
        default: measure_ch2o,
        active: null,
      };
    case 'measure_co':
      return {
        default: measure_co,
        active: null,
      };
    case 'measure_co2':
      return {
        default: measure_co2,
        active: null,
      };
    case 'measure_content_volume':
      return {
        default: measure_content_volume,
        active: null,
      };
    case 'measure_current':
      return {
        default: measure_current,
        active: null,
      };
    case 'measure_data_rate':
      return {
        default: measure_data_rate,
        active: null,
      };
    case 'measure_data_size':
      return {
        default: measure_data_size,
        active: null,
      };
    case 'measure_distance':
      return {
        default: measure_distance,
        active: null,
      };
    case 'measure_frequency':
      return {
        default: measure_frequency,
        active: null,
      };
    case 'measure_gust_angle':
      return {
        default: measure_gust_angle,
        active: null,
      };
    case 'measure_gust_strength':
      return {
        default: measure_gust_strength,
        active: null,
      };
    case 'measure_hepa_filter':
      return {
        default: measure_hepa_filter,
        active: null,
      };
    case 'measure_humidity':
      return {
        default: measure_humidity,
        active: null,
      };
    case 'measure_luminance':
      return {
        default: measure_luminance,
        active: null,
      };
    case 'measure_moisture':
      return {
        default: measure_moisture,
        active: null,
      };
    case 'measure_monetary':
      return {
        default: measure_monetary,
        active: null,
      };
    case 'measure_noise':
      return {
        default: measure_noise,
        active: null,
      };
    case 'measure_nox':
      return {
        default: measure_nox,
        active: null,
      };
    case 'measure_o3':
      return {
        default: measure_o3,
        active: null,
      };
    case 'measure_odor':
      return {
        default: measure_odor,
        active: null,
      };
    case 'measure_ph':
      return {
        default: measure_ph,
        active: null,
      };
    case 'measure_pm1':
      return {
        default: measure_pm1,
        active: null,
      };
    case 'measure_pm01':
      return {
        default: measure_pm01,
        active: null,
      };
    case 'measure_pm10':
      return {
        default: measure_pm10,
        active: null,
      };
    case 'measure_pm25':
      return {
        default: measure_pm25,
        active: null,
      };
    case 'measure_power':
      return {
        default: measure_power,
        active: null,
      };
    case 'measure_pressure':
      return {
        default: measure_pressure,
        active: null,
      };
    case 'measure_radon':
      return {
        default: measure_radon,
        active: null,
      };
    case 'measure_rain':
      return {
        default: measure_rain,
        active: null,
      };
    case 'measure_rain_intensity':
      return {
        default: measure_rain_intensity,
        active: null,
      };
    case 'measure_rotation':
      return {
        default: measure_rotation,
        active: null,
      };
    case 'measure_signal_strength':
      return {
        default: measure_signal_strength,
        active: null,
      };
    case 'measure_so2':
      return {
        default: measure_so2,
        active: null,
      };
    case 'measure_speed':
      return {
        default: measure_speed,
        active: null,
      };
    case 'measure_temperature':
      return {
        default: measure_temperature,
        active: null,
      };
    case 'measure_tvoc':
      return {
        default: measure_tvoc,
        active: null,
      };
    case 'measure_tvoc_index':
      return {
        default: measure_tvoc_index,
        active: null,
      };
    case 'measure_ultraviolet':
      return {
        default: measure_ultraviolet,
        active: null,
      };
    case 'measure_voltage':
      return {
        default: measure_voltage,
        active: null,
      };
    case 'measure_water':
      return {
        default: measure_water,
        active: null,
      };
    case 'measure_weight':
      return {
        default: measure_weight,
        active: null,
      };
    case 'measure_wind_angle':
      return {
        default: measure_wind_angle,
        active: null,
      };
    case 'measure_wind_strength':
      return {
        default: measure_wind_strength,
        active: null,
      };
    case 'meter_gas':
      return {
        default: meter_gas,
        active: null,
      };
    case 'meter_power':
      return {
        default: meter_power,
        active: null,
      };
    case 'meter_rain':
      return {
        default: meter_rain,
        active: null,
      };
    case 'meter_water':
      return {
        default: meter_water,
        active: null,
      };
    case 'operational_state':
      return {
        default: operational_state,
        active: null,
      };
    default:
      if (capability.iconObj) {
        return {
          default: `https://icons-cdn.athom.com/${capability.iconObj.id}.svg`,
          active: `https://icons-cdn.athom.com/${capability.iconObj.id}.svg`,
          useIconObj: true,
        };
      }
      return {
        default: generic_device,
        active: generic_device,
      };
  }
}
